<template>
    <SmartTable url="/sodalizi/documenti/index" base_path="/sodalizi-documenti"></SmartTable>
</template>

<script>
 import SmartTable from "@/view/components/SmartTable.vue";
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     components: {
         SmartTable
     },
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Documenti Sodalizio", route: "/sodalizi-documenti/index" },
             { title: "Ricerca Documenti Sodalizio" }
         ]);
     }
 };
</script>
